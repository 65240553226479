
import { Swiper, SwiperSlide } from 'swiper/react';


import pazFlor from "../../assets/images/paz-flor.jpg";
import latiangol from "../../assets/images/Latiangol.jpg";
import noblesse from "../../assets/images/noblesse.jpeg";
import belasPark from "../../assets/images/bella-park.jpeg";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

export function ClientCard() {
  return (
    <>
        <Swiper
            spaceBetween={50}
            // slidesPerView={3}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              991: {
                width: 991,
                slidesPerView: 3,
              },
              768: {
                width: 768,
                slidesPerView: 2,
              },
              320: {
                width: 320,
                slidesPerView: 1,
              },           
            }}

        >
            <SwiperSlide className="slide">
                <i className="fas fa-quote-right"></i>
                <div className="client">
                    <img src={noblesse} alt="Cliente 1" />
                    <div className="client-info">
                      <h3>Condomínio Nobless</h3>
                      <div className="satrs">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>

                <p>A fiamendes nos conquistou pela dedicação, pontualidade e bom 
                  custo beneficio. Depois de buscar parceiros na área, finalmente 
                  encontramos uma empresa capaz de atender as nossas demandas</p>
            </SwiperSlide>
            <SwiperSlide className="slide">
                <i className="fas fa-quote-right"></i>
                <div className="client">
                    <img src={belasPark} alt="Cliente 5" />
                    <div className="client-info">
                      <h3>Cdn. Belas Business Park</h3>
                      <div className="satrs">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>

                <p>A fiamendes vem prestando serviços para nós e nossos clientes
                   sempre de forma profissional respeitando prazos e entregando 
                   bons resultados</p>
            </SwiperSlide>
            <SwiperSlide className="slide">
                <i className="fas fa-quote-right"></i>
                <div className="client">
                    <img src={pazFlor} alt="Cliente 6" />
                    <div className="client-info">
                      <h3>Centro Cultural Paz Flor</h3>
                      <div className="satrs">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>

                <p>Há alguns anos que temos trabalhado com a fia mendes e somos 
                  testemunha do profissionalismo e dedicação</p>
            </SwiperSlide>
            <SwiperSlide className="slide">
                <i className="fas fa-quote-right"></i>
                <div className="client">
                    <img src={latiangol} alt="Cliente 6" />
                    <div className="client-info">
                      <h3>Empresa Latiangol</h3>
                      <div className="satrs">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                      </div>
                    </div>
                </div>

                <p>A fiamendes trabalha de forma séria, com profissionais competentes
                  e um detalhe importante: bastante atenciosos. super recomendamos</p>
            </SwiperSlide>

        </Swiper>
    
    </>
  )
}
