// import { Swiper, SwiperSlide } from 'swiper/react';

import {ClientCard} from '../../components/ClientCard';

import shape from '../../assets/images/header-shape.svg';

import farmaciaImg from "../../assets/images/farmacia.jpg";
import graficaImg from "../../assets/images/grafica.jpg";
import depositoImg from "../../assets/images/deposito.jpg";
import servicosImg from "../../assets/images/deposito.jpg";

import './styles.css'

// import 'swiper/css'


export function Home() {

  return(
    <>

    

      {/* Home Sections Start */}
      <div className="home" id="home">
        
        <section>
            <div className="banner">
                <div className="title-text">
                  <h3>Bem vindos à FiaMendes</h3>
                </div>
      
              <div className="subtitle-text"><p>Um Grupo Cheio de Soluções para o Teu Negocio</p></div> 
                <a href="?#" className="btn">Saiba mais</a>
            </div>   
            
            <div className="hero-shape">
              <img src={shape} alt="ondas do banner da home" />
            </div>
        </section>
        
        

      </div>
        {/*  Home Sections Ends  */}


        {/* Home-Main Sections Start */}
        <section className="group-service" id="services">

        <h1 className="heading"> Nossos <span>Seviços</span></h1>

        
        
        <div className="row">
            <div className="image">
              <img src={depositoImg} alt="deposito de medicamentos fiamendes" />
            </div>

            <div className="content">
                <h3>Deposito de Medicamentos FiaMendes</h3>
                <p>
                  Sito em Luanda Bairro Zango 1, distrito Urbano do Zango Rua S/Nº,
                  Casa S/Nº, Municipio de Viana, actuamos em todo territorio nacional
                  desde 2011 oferecendo serviços de assistencia técnica e reparação de 
                  equipamentos hospitalares, venda de produtos médicos e medicamentos...
                </p>         
            </div>
        </div>
        
        
        <div className="row">
            <div className="content">
                <h3>AMIVIK Farmacias</h3>
                <p>
                  Considerada pelos nossos Clientes dentre as melhores Farmácias da capital,
                  prestamos um serviço de excelência, Distinguindo-se na competência técnica
                  e rigor dos nossos profissionais, assim como pela diversidade, versatilidade 
                  dos produtos e serviços. Temos ao seu dispor 2 Farmácias abertas 24h / 7.
                </p>
                
                <p> <strong>Farmácia AMIVIK 1 Rua direita do Zango</strong></p>  
                <p> <strong>Farmácia AMIVIK 2  no Distrito da Maianga Avº 21 de Janeiro</strong></p>
            </div>

            <div className="image">
              <img src={farmaciaImg} alt="farmacias AMIVIK" />
            </div>
        </div>

        <div className="row">
            <div className="image">
              <img src={graficaImg} alt="grafica fiamendes" />
            </div>

            <div className="content">
                <h3>Grafica FiaMendes</h3>
                <p>
                  A FM Designer Grafico conta com quadros qualificados que oferecem
                  os melhores serviços com a qualidade obrigada pelo mercado. Sita em 
                  Luanda, Bairro Cassenda Rua nº 3 Casa nº 34, deste 2011 que atuamos no mercado
                  nas seguintes áreas:                 
                </p>
                <p>
                  Designer Grafico / Criação de Logotipo <br />
                  Cartazes Publicitario / Cartões de Visita <br />
                  Passes em PVC / Impressão em Envelopes (A1,A3,A4,A5,A6, DL) <br />
                  Timbragem em Polos, T-shirts / Fitas Porta Passe <br />
                  Impressão de Lona em Vinil e(ou) Normal / Perfurado e(ou) Transparente, Rollup <br />
                  Coletes de Multi-Bolso e Refletor, Bonés.
                </p>
                 
               
            </div>

        </div>

        <div className="row">
  
            <div className="content">
                <h3>Serviço de Abastecimento de Água</h3>
                <p>
                  Temos 2 cisternas com capacidade de carregamento entre 20.000 Litros e 
                  40.000 Litros, uma equipa munida com todas a medidas e normais de segurança 
                  exigidas e estamos disponiveis 24/7, pelo que estaremos em condições de satisfazer 
                  as necessidades dos nossos clientes.               
                </p>
            </div>

            <div className="image">
              <img src={servicosImg} alt="fiamendes prestacoes de serviços" />
            </div>
        </div>

        </section>

        <div className="module parallax">
        </div>

        <section className="clients-group" id="clients-group">
          <h3 className="heading"> Nossos <span>Clientes</span></h3>
          <h1> O que eles dizem </h1>


          <ClientCard />

        </section>

    </>
  )
}